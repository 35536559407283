<template>
  <div />
</template>

<script>
export default {
  watch: {
    '$auth.loading': function authLoadWatcher() {
      this.logout()
    },
  },

  created() {
    if (!this.$auth.loading) {
      this.logout()
    }
  },

  methods: {
    logout() {
      let returnTo
      const { roles } = this.$auth

      sessionStorage.clear()
      this.$cookies.remove('api_token', '', this.$store.getters['app/getDomain'])
      this.$cookies.remove('is_registered', '', this.$store.getters['app/getDomain'])
      this.$cookies.remove('is_subscribed', '', this.$store.getters['app/getDomain'])

      if (roles.includes('ROLE_CUSTOMER')) {
        returnTo = window.location.origin
      }

      // Logout in Auth0
      this.$auth.logout({ returnTo })
    },
  },
}
</script>
